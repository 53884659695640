import regexUtil from '@/utils/regex';
import { createValidationObjectSchema } from '@/utils/validations';

export const Account = createValidationObjectSchema({
  email: {
    format: 'email',
    required: true,
  },
  password: {
    required: true,
    match: [
      regexUtil.password,
      'Password at least 8 characters, has a Uppercase, lowercase, numbers, and a special char., (i.e., Password123!)',
    ],
  },
  password_confirmation: {
    required: true,
    oneOf: ['password', 'Confirm Password did not match password'],
  },
  // mobile_number: {
  //   match: [regexUtil.mobile['generic'], 'Mobile number is not a valid number'],
  //   required: true,
  // },
});

export const ResetPassword = createValidationObjectSchema({
  password: {
    required: true,
    match: [
      regexUtil.password,
      //'Password at least 8 characters, has a Uppercase, lowercase, numbers, special char., (i.e., Password123!)',
      'Please enter a valid password.',
    ],
  },
  password_confirmation: {
    required: true,
    oneOf: ['password', 'Confirm Password did not match password'],
  },
  // mobile_number: {
  //   match: [regexUtil.mobile['generic'], 'Mobile number is not a valid number'],
  //   required: true,
  // },
});

export const AuthSchema = createValidationObjectSchema({
  email: {
    format: 'email',
    required: true,
  },
  password: {
    required: true,
    match: [
      regexUtil.password,
      'Please enter a valid password (i.e., Password123!)',
    ],
  },
});

export const Address = createValidationObjectSchema({
  address_line: {
    required: true,
  },
  city: {
    required: true,
  },
  state: {
    required: true,
  },
  country: {
    required: true,
  },
  post_code: {
    required: true,
    match: [regexUtil.mobile.generic, 'Postcode is invalid'],
  },
});

export const ValidEmail = createValidationObjectSchema({
  email: {
    format: 'email',
    required: true,
    match: [regexUtil.email, 'Please enter a valid email'],
  },
});
